import Link from "next/link";
import type { ReactElement } from "react";
import { PrimaryLayout } from "@/layouts/PrimaryLayout";
import { NextPageWithLayout } from "./_app";
import { NextSeo } from "next-seo";
import { BsArrowRight} from "react-icons/bs";
import React from "react";
import {CHAIN_ID, DEMO_ETH, getOgImage, PERPS_VIEW} from "@/util/constants";
import {TestNFTMinter} from "@/components/Mock/TestNFTMinter";
import {CompetitionSectionHeader} from "@/components/Competition/CompetitionSectionHeader";
import {FaCoins} from "react-icons/fa";
import {DemoEthAbi} from "@/contract/DemoEthAbi";

const Page: NextPageWithLayout = () => {
  const isPerpsView = PERPS_VIEW;
  const renderButton = (href: string, label: string, subtitle: string) => {
    return <Link href={href}>
      <div className="p-8 flex flex-col items-center justify-center border-[1px] border-neutral w-[250px] h-[100px] rounded-md hover:cursor-pointer bg-glass hover:bg-glass-focus">
        <div className="text-[18px] flex flex-row items-center justify-center gap-1">
          {label} <BsArrowRight />
        </div>
        <div className="text-xs text-neutral-content">
          {subtitle}
        </div>
      </div>
    </Link>
  }

  return (
    <div className="py-16 flex flex-col items-center justify-center flex-1 gap-4 text-center">
      <NextSeo title="Wasabi"
               description="The Decentralized Leverage Protocol"
               canonical="https://app.wasabi.xyz"
               openGraph={{
                 url: "https://app.wasabi.xyz",
                 title: "Wasabi",
                 description: "The Decentralized Leverage Protocol",
                 images: [
                   {
                     url: getOgImage(),
                     width: 1200,
                     height: 630,
                     alt: "Wasabi"
                   }
                 ]
               }}
      />
      <div>{isPerpsView ? "LONG, SHORT AND EARN" : "SPECULATE, HEDGE AND EARN"}</div>
      <h1 className="text-3xl sm:text-6xl font-extrabold text-center py-2">
        {
          isPerpsView ?
            <>NFT Perpetuals Protocol</> :
            <><i>The</i> Decentralized NFT Options Protocol</>
        }
      </h1>
      <p className="text-neutral-content">
        {
          isPerpsView ?
            <>
              Long or short NFTs with leverage.
              <br />
              Earn by providing liquidity to the protocol.
            </> :
            <>
              Collectors issue options contracts to make stable, low-risk income
              by collecting premiums.
              <br />
              Market participants capitalize on the market's volatility by
              exercising or trading options.
            </>
        }
      </p>
      <div className="responsive-flex text-center gap-4 min-w-[50%] justify-center items-center">
        {
          isPerpsView ?
            <>
              {renderButton("/", "Trade", "Trade with leverage")}
              {renderButton("/vaults", "Earn", "Earn by depositing to vaults")}
            </> :
            <>
              {renderButton("/pool/new", "Sell Options", "Earn by collecting premiums")}
              {renderButton("/collections", "Buy Options", "Earn by speculating")}
            </>
        }

      </div>
      {
        !isPerpsView && CHAIN_ID !== 1 &&
        <div className="standard-frame p-4 max-w-[500px]">
          <CompetitionSectionHeader icon={<FaCoins />} title="Demo ETH" />
          <div className="text-neutral-content text-left">
            You will need to use our test ERC-20 token to participate in the trading competition.
            Please mint the Demo ETH in order to start trading.
            You will receive 100 DETH and can only mint once.
          </div>
          <TestNFTMinter abi={DemoEthAbi} address={DEMO_ETH} autoStart={false} />
        </div>
      }
    </div>
  );
};

Page.getLayout = function getLayout(page: ReactElement) {
  return <PrimaryLayout skipGeofence={true}>{page}</PrimaryLayout>;
};

export default Page;
